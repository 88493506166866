/* eslint-disable react/forbid-prop-types */
import "moment/locale/es"
import moment from "moment"
import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

// Icons
import { Question } from "@styled-icons/bootstrap/Question"

// Styles
import {
  CardWrapper,
  InfoWrapper,
  Name,
  QuestionText,
  InitialAvatar,
  PersonalWrapper,
  Type,
  ApponitmentType,
  AptTypeText,
  Actions,
  Action,
  QuestionContainer,
  TimeText,
} from "./styles"

// Utils
import { withFirebase } from "../../../utils/Firebase"

const QuestionCard = ({ question }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const createMarkup = () => ({ __html: question?.question?.question })

  return (
    <CardWrapper>
      <ApponitmentType bgColor="#FFFFF0">
        <AptTypeText color="#D69E2E">
          {t("web_client.card_question")}
        </AptTypeText>
        <TimeText color="#D69E2E">
          {`${moment(question?.submittedAt).format("MMMM D")},
                ${moment(question?.submittedAt).format("h:mm A")}`}
        </TimeText>
      </ApponitmentType>

      <InfoWrapper>
        <InitialAvatar>
          <span>{question?.clientName[0]}</span>
        </InitialAvatar>
        <PersonalWrapper>
          <Name>{question?.clientDisplayName?.toUpperCase()}</Name>
          <QuestionContainer>
            <QuestionText>
              <div dangerouslySetInnerHTML={createMarkup()} />
            </QuestionText>
          </QuestionContainer>
        </PersonalWrapper>
      </InfoWrapper>
      <Type>
        <Question size="25" color="#FFFFFF" />
      </Type>
      <Actions>
        <Action
          disabled={false}
          onClick={async (e) => {
            e.stopPropagation()
            history.push("/questions", {
              question,
              isFromDashboard: true,
            })
          }}
        >
          {t("web_client.question_answer")}
        </Action>
      </Actions>
    </CardWrapper>
  )
}

QuestionCard.propTypes = {
  question: PropTypes.any.isRequired,
}

export default withFirebase(QuestionCard)
