import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import Creatable from "react-select/creatable"
import { useHistory, Link } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import { useTranslation } from "react-i18next"
import get from "lodash.get"
import { v4 as uuidv4 } from "uuid"
import CustomModal from "../../atoms/CustomModal"
import { prepareMessage } from "../../../utils/helpers"
import {
  honeybadger,
  v3sessionsCloseUrl,
  // eslint-disable-next-line no-unused-vars
  sessionsDiscountUrl,
  getProviderPricingUrl,
  sessionsDonateUrl,
} from "../../../utils/App"
import { withFirebase } from "../../../utils/Firebase"
import { InitialAvatar, Name } from "../../atoms/Card/styles"
import Theme from "../../../styles/theme"
import {
  HeaderWrapper,
  SubmitButton,
  ModalWrapper,
  ModalOptions,
  DiscountsWrapper,
  NameWrapper,
  ContactLink,
  AddButton,
  RegularFont,
  DiagnosisModal,
  RegularFontBlack,
} from "./styles"

const customStyles = {
  container: (provided) => ({
    ...provided,
    marginLeft: 2,
  }),
  control: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    borderRadius: state.selectProps.borderRadius,
    borderColor: state.selectProps.showError
      ? Theme.color.alert
      : Theme.color.iceBlue,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = "opacity 300ms"

    return { ...provided, opacity, transition }
  },
}

const ChatHeader = ({
  name,
  showButton,
  firebase,
  handleClick,
  session,
  serverTimeOffset,
  user,
  donated,
  info,
  sessionDiscount,
  isDependentChat,
  isContact,
  handleAddingContact,
}) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const history = useHistory()
  const [isDonating, setIsDonating] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [isConfirming, setIsConfirming] = useState(false)
  const [sessionDonated, setSessionDonated] = useState(donated)
  const [processingDiscount, setProcessingDiscount] = useState(false)
  const [discount, setDiscount] = useState(0)
  const [causes, setCauses] = useState([])
  const [diagnoses, setDiagnoses] = useState([])
  const [isDiagnosisModalVisible, setIsDiagnosisModalVisible] = useState(false)
  const [patientNeedsDisability, setPatientNeedsDisability] = useState(false)
  const [patientDisabilityDays, setPatientDisabilityDays] = useState(0)
  const [diagnosisCausePairs, setDiagnosisCausePairs] = useState([
    { diagnosis: null, cause: null },
  ])

  useEffect(() => {
    if (isButtonLoading) {
      setTimeout(() => {
        setIsButtonLoading(false)
      }, 1000 / 5)
    }
  }, [isButtonLoading])

  useEffect(() => {
    const processDiscount = async () => {
      try {
        const authToken = await firebase.getIdToken()
        const params = {
          email: user.isImpersonating ? user.impersonatingEmail : user.email,
        }
        const url = new URL(getProviderPricingUrl)
        Object.keys(params).forEach((k) => {
          url.searchParams.append(k, params[k])
        })

        const providerPricingData = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        })

        if (providerPricingData.ok) {
          const pricing = await providerPricingData.json()
          const response = await fetch(sessionsDiscountUrl, {
            method: "POST",
            body: JSON.stringify({
              chatId: session,
              discount,
              pricing,
            }),
            headers: {
              Authorization: `Bearer ${authToken}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          if (response.ok) {
            addToast(t("discount_applied"), { appearance: "success" })
            setIsDiscountModalOpen(false)
            setProcessingDiscount(false)
          } else {
            setProcessingDiscount(false)
            addToast(t("unexpected_error"), { appearance: "error" })
          }
        } else {
          setProcessingDiscount(false)
          addToast(t("unexpected_error"), { appearance: "error" })
        }
      } catch (err) {
        setProcessingDiscount(false)
        addToast(t("unexpected_error"), { appearance: "error" })
      }
    }

    if (processingDiscount) {
      processDiscount()
    }
  }, [session, discount, processingDiscount])

  useEffect(() => {
    const checkContact = async () => {
      const data = await firebase.checkContact(
        user.isImpersonating ? user.impersonatingEmail : user.email,
        info.clientEmail
      )
      if (data && data.docs.length > 0) {
        handleAddingContact(true)
      } else {
        handleAddingContact(false)
      }
    }

    const getCausesAndDiagnoses = async () => {
      try {
        const causesFromApi = await firebase.getCauses()

        const causesArray = []
        causesFromApi.forEach((cause) => {
          causesArray.push(cause.data())
        })

        setCauses(causesArray)

        const diagnosesFromApi = await firebase.getDiagnoses()

        const diagnosesArray = []
        diagnosesFromApi.forEach((diagnosis) => {
          diagnosesArray.push(diagnosis.data())
        })

        setDiagnoses(diagnosesArray)
      } catch (error) {
        window.Honeybadger?.notify(error)
      }
    }

    if (user && info) {
      checkContact()
      getCausesAndDiagnoses()
    }
  }, [user, info])

  const sendMessage = (msg) => {
    const prepMsg = prepareMessage(
      user,
      serverTimeOffset,
      msg,
      msg.type || "text",
      msg.targetTokens || []
    )
    return firebase.pushMessage({ chat: session, message: prepMsg })
  }

  const donateSession = async () => {
    const tokens = localStorage.getItem(`targetTokens-${session}`)
    const authToken = await firebase.getIdToken()
    sendMessage({
      _id: uuidv4(),
      text: "session_donated",
      type: "system",
      targetTokens: JSON.parse(tokens),
    })

    const response = await fetch(sessionsDonateUrl, {
      method: "POST",
      body: JSON.stringify({
        chatId: session,
      }),
      headers: {
        Authorization: `Bearer ${authToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })

    if (response.ok) {
      setSessionDonated(true)
      setIsDonating(false)
    }
  }

  const handleOptionClick = (option) => {
    if (option === "finish") {
      setSelectedOption("finish")
      setIsModalOpen(true)
    } else {
      setIsDiscountModalOpen(false)
      setIsDonating(true)
      setSelectedOption("donate")
      setIsModalOpen(true)
    }
  }

  const handleModalCancel = () => {
    setIsModalOpen(false)
    setSelectedOption(null)
    setIsDonating(false)
  }

  const startVideoSession = () => {
    const tokens = localStorage.getItem(`targetTokens-${session}`)
    const title = get(user, ["medicProfile", "title"])
    const providerName = title
      ? `${title} ${get(user, ["medicProfile", "displayName"])}`
      : get(user, ["medicProfile", "displayName"])
    sendMessage({
      _id: uuidv4(),
      text: `video_session_started|${session}|${providerName}`,
      type: "system",
      targetTokens: JSON.parse(tokens),
    })
    handleClick()
  }

  const handleFinish = async () => {
    try {
      const idToken = await firebase.getIdToken()
      setIsConfirming(true)
      if (selectedOption === "finish") {
        // const date = new Date()
        const params = {
          slotID: info.appointmentId,
        }
        const query = Object.keys(params)
          .map(
            (k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`
          )
          .join("&")
        const response = await fetch(`${v3sessionsCloseUrl}?${query}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${idToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })

        if (response.ok) {
          setIsConfirming(false)
          setIsModalOpen(false)
          addToast(t("session_finished"), { appearance: "success" })
          setTimeout(() => {
            history.push("/sessions")
          }, 1000)
        }
      } else {
        setIsConfirming(false)
        setIsModalOpen(false)
        donateSession()
      }
    } catch (e) {
      setIsConfirming(false)
      addToast(t("unexpected_error"), { appearance: "error" })
    }
  }

  const applyDiscount = (amount) => {
    setDiscount(amount)
    setProcessingDiscount(true)
  }

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          event.stopPropagation()
        }
      }
      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }, [ref])
  }

  const addContact = async () => {
    const userData = await firebase.getUserContactData(info.clientEmail)

    if (!userData.exists) {
      addToast(t("unexpected_error"), { appearance: "error" })
      honeybadger.notify("Session - adding contact", {
        message: "Not user found for the email",
        action: "create",
        context: {
          error: {
            email: info.clientEmail,
          },
        },
      })
    }

    const parsedData = userData.data()
    const contact = {
      email: info.clientEmail,
      displayName: parsedData.displayName,
      isFavorite: false,
      phoneNumber: parsedData?.phoneNumber?.formattedNumber,
      userType: "client",
      country: parsedData?.country,
    }

    try {
      const data = await firebase.checkContact(
        user.isImpersonating ? user.impersonatingEmail : user.email,
        info.clientEmail
      )

      if (data && data.empty) {
        await firebase.addContact({
          doctor: user.isImpersonating ? user.impersonatingEmail : user.email,
          contact,
        })
        handleAddingContact(true)
        addToast(t("web_client.contact_added_successfully"), {
          appearance: "success",
        })
      } else if (!data.empty) {
        // already exists
        handleAddingContact(true)
      } else {
        addToast(t("unexpected_error"), { appearance: "error" })
        honeybadger.notify("Session - adding contact", {
          message: "Error adding contact",
          action: "create",
          context: {
            error: {
              email: info.clientEmail,
            },
          },
        })
      }
    } catch (error) {
      addToast(t("unexpected_error"), { appearance: "error" })
      honeybadger.notify("Session - general contact", {
        message: "Error adding/checking contact",
        action: "create",
        context: {
          error,
        },
      })
    }
  }

  const handleCreateCause = async (inputValue) => {
    try {
      const newCause = {
        label: inputValue,
        value: inputValue.toLowerCase().replace(/\s+/g, "_"),
      }

      const existingCause = causes.some(
        (cause) => cause.label === inputValue.toLowerCase().replace(/\s+/g, "_")
      )

      if (existingCause) {
        addToast(t("add_cause_exists"), {
          appearance: "error",
          placement: "top-right",
        })
        return
      }

      setCauses((prevCauses) => [...prevCauses, newCause])

      setDiagnosisCausePairs((prevPairs) => {
        const updatedPairs = [...prevPairs]
        updatedPairs.forEach((pair) => {
          if (!pair.cause) {
            // eslint-disable-next-line no-param-reassign
            pair.cause = newCause
          }
        })
        return updatedPairs
      })

      await firebase.createCauseOrDiagnose({
        collection: "causes",
        record: newCause,
      })
    } catch (error) {
      window.Honeybadger?.notify(error)
    }
  }

  const handleCreateDiagnose = async (inputValue) => {
    try {
      const newDiagnose = {
        label: inputValue,
        value: inputValue.toLowerCase().replace(/\s+/g, "_"),
      }

      const existingDiagnose = diagnoses.some(
        (diagnose) =>
          diagnose.label === inputValue.toLowerCase().replace(/\s+/g, "_")
      )

      if (existingDiagnose) {
        addToast(t("add_diagnose_exits"), {
          appearance: "error",
          placement: "top-right",
        })
        return
      }

      setDiagnoses((prevDiagnoses) => [...prevDiagnoses, newDiagnose])

      setDiagnosisCausePairs((prevPairs) => {
        const updatedPairs = [...prevPairs]
        updatedPairs.forEach((pair) => {
          if (!pair.cause) {
            // eslint-disable-next-line no-param-reassign
            pair.diagnosis = newDiagnose
          }
        })
        return updatedPairs
      })

      await firebase.createCauseOrDiagnose({
        collection: "diagnoses",
        record: newDiagnose,
      })
    } catch (error) {
      window.Honeybadger?.notify(error)
    }
  }

  const handleCreateNewDiagnose = async () => {
    try {
      const records = diagnosisCausePairs.map((pair) => {
        const recordData = {
          diagnosis: pair.diagnosis,
          cause: pair.cause,
          patientNeedsDisability,
          patientDisabilityDays,
          createdAt: Date.now(),
          createdByEmail: user.isImpersonating
            ? user.impersonatingEmail
            : user.email,
          createdBy: `${user?.medicProfile?.title} ${user?.medicProfile?.displayName}`,
          sessionId: session,
          type: "diagnosis",
        }

        return firebase.addMedicalRecord({
          contactEmail: info.clientEmail,
          record: recordData,
        })
      })

      await Promise.all(records)

      setIsDiagnosisModalVisible(false)

      setTimeout(() => {
        handleOptionClick("finish")
      }, 1000)
    } catch (error) {
      window.Honeybadger?.notify(error)
    }
  }

  const handlePatientNeedsDisability = (e) => {
    e.persist()
    setPatientNeedsDisability(!patientNeedsDisability)
  }

  const isBusinessUser =
    user?.medicProfile?.accessMode === "business" ||
    user?.medicProfile?.accessMode === "mix"

  const handleDiagnosisClick = () => {
    if (isBusinessUser) {
      setIsDiagnosisModalVisible(!isDiagnosisModalVisible)
    } else {
      handleOptionClick("finish")
    }
  }

  const addNewPair = () => {
    setDiagnosisCausePairs([
      ...diagnosisCausePairs,
      { diagnosis: null, cause: null },
    ])
  }

  const updateDiagnosis = (index, selectedDiagnosisOption) => {
    setDiagnosisCausePairs((prevPairs) => {
      const updatedPairs = [...prevPairs]
      updatedPairs[index].diagnosis = selectedDiagnosisOption
      return updatedPairs
    })
  }

  const updateCause = (index, selectedCauseOption) => {
    setDiagnosisCausePairs((prevPairs) => {
      const updatedPairs = [...prevPairs]
      updatedPairs[index].cause = selectedCauseOption
      return updatedPairs
    })
  }

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  return (
    <>
      <HeaderWrapper>
        <div>
          <InitialAvatar>
            <span>{name?.[0]?.toString()?.toUpperCase()}</span>
          </InitialAvatar>
          <NameWrapper>
            <Name>
              {isDependentChat ? `${name} (Atendiendo Dependiente)` : name}
            </Name>
            {isContact && showButton && (
              <Link
                to={{
                  pathname: "/contacts",
                  state: {
                    contact: {
                      email: info.clientEmail,
                      displayName: info.clientName,
                    },
                    session,
                  },
                }}
              >
                <ContactLink>{t("web_client.look_contact")}</ContactLink>
              </Link>
            )}
            {!isContact && showButton && (
              <AddButton type="button" onClick={addContact}>
                + {t("add_contact")}
              </AddButton>
            )}
          </NameWrapper>
        </div>
        {showButton && (
          <div>
            {info?.sessionType !== "faceToFace" && (
              <SubmitButton
                iconName="video"
                isLoading={isButtonLoading}
                type="button"
                onClick={startVideoSession}
                color={Theme.color.secondary}
                small
              />
            )}

            {/* {!donated && info?.sessionType !== "faceToFace" && (
              <SubmitButton
                isLoading={isDonating}
                type="submit"
                onClick={() => {
                  setIsDiscountModalOpen(true)
                }}
                color={Theme.color.secondary}
                medium
              >
                {t("apply_discount")}
              </SubmitButton>
            )} */}
            <SubmitButton
              isLoading={isModalOpen}
              type="submit"
              onClick={() => handleDiagnosisClick()}
              color="red"
              medium
            >
              {t("finish_session")}
            </SubmitButton>
          </div>
        )}
      </HeaderWrapper>
      <CustomModal
        modalOpen={isModalOpen}
        setModalOpen={setIsModalOpen}
        showClose={false}
      >
        <ModalWrapper isFull>
          <ModalOptions>
            <section>
              <p>
                {selectedOption === "finish"
                  ? t("system_session_check_before_finish")
                  : t("system_session_check_before_donate")}
              </p>
              <div style={{ marginTop: "50px" }}>
                <button type="button" onClick={handleModalCancel}>
                  {t("cancel")}
                </button>
                <SubmitButton
                  isLoading={isConfirming}
                  type="button"
                  onClick={handleFinish}
                  color={Theme.color.secondary}
                  small
                >
                  {t("confirm")}
                </SubmitButton>
              </div>
            </section>
          </ModalOptions>
        </ModalWrapper>
      </CustomModal>
      <CustomModal
        modalOpen={isDiscountModalOpen}
        setModalOpen={setIsDiscountModalOpen}
        showClose={false}
      >
        <ModalWrapper isFull>
          <ModalOptions>
            <section>
              <p
                style={{
                  marginBottom: "10px",
                }}
              >
                {sessionDiscount > 0
                  ? t("already_applied_discount_text")
                  : t("apply_discount_text")}
              </p>
              {sessionDiscount === 0 && (
                <DiscountsWrapper isCancel>
                  <SubmitButton
                    disabled={isDonating}
                    isLoading={isDonating}
                    type="submit"
                    onClick={() =>
                      donated || sessionDonated || isDonating
                        ? null
                        : handleOptionClick("donate")
                    }
                    color={Theme.color.secondary}
                  >
                    {t("donate_session")}
                  </SubmitButton>
                </DiscountsWrapper>
              )}
              {sessionDiscount === 0 && (
                <DiscountsWrapper>
                  <SubmitButton
                    disabled={processingDiscount}
                    isLoading={processingDiscount && discount === 0.75}
                    type="submit"
                    onClick={() =>
                      processingDiscount ? null : applyDiscount(0.75)
                    }
                    color={Theme.color.secondary}
                    small
                  >
                    75%
                  </SubmitButton>
                  <SubmitButton
                    disabled={processingDiscount}
                    isLoading={processingDiscount && discount === 0.5}
                    type="submit"
                    onClick={() =>
                      processingDiscount ? null : applyDiscount(0.5)
                    }
                    color={Theme.color.secondary}
                    small
                  >
                    50%
                  </SubmitButton>
                  <SubmitButton
                    disabled={processingDiscount}
                    isLoading={processingDiscount && discount === 0.25}
                    type="submit"
                    onClick={() =>
                      processingDiscount ? null : applyDiscount(0.25)
                    }
                    color={Theme.color.secondary}
                    small
                  >
                    25%
                  </SubmitButton>
                  <SubmitButton
                    disabled={processingDiscount}
                    isLoading={processingDiscount && discount === 0.1}
                    type="submit"
                    onClick={() =>
                      processingDiscount ? null : applyDiscount(0.1)
                    }
                    color={Theme.color.secondary}
                    small
                  >
                    10%
                  </SubmitButton>
                </DiscountsWrapper>
              )}
              {sessionDiscount > 0 && (
                <DiscountsWrapper isCancel>
                  <SubmitButton
                    disabled={processingDiscount}
                    isLoading={processingDiscount && discount === 0}
                    type="submit"
                    onClick={() =>
                      processingDiscount ? null : applyDiscount(0)
                    }
                    color="red"
                  >
                    {t("remove_discount")}
                  </SubmitButton>
                </DiscountsWrapper>
              )}
              <div style={{ marginTop: "50px" }}>
                <button
                  type="button"
                  onClick={() => setIsDiscountModalOpen(false)}
                >
                  {t("cancel")}
                </button>
              </div>
            </section>
          </ModalOptions>
        </ModalWrapper>
      </CustomModal>

      <CustomModal modalOpen={isDiagnosisModalVisible} showClose={false}>
        <ModalWrapper>
          <DiagnosisModal>
            <section>
              <RegularFontBlack>
                {t("web_client.diagnosis_paragraph")}
              </RegularFontBlack>
              <RegularFont>{t("web_client.diagnosis_disclaimer")}</RegularFont>

              <section style={{ marginTop: 30, marginLeft: 15 }}>
                <RegularFontBlack color="black">
                  {t("web_client.select_diagnosis")}
                </RegularFontBlack>
                {diagnosisCausePairs.map((pair, index) => (
                  <div key={index} style={{ marginBottom: 20 }}>
                    <Creatable
                      styles={customStyles}
                      options={diagnoses}
                      value={pair.diagnosis}
                      onChange={(selectedDiagnosisOption) =>
                        updateDiagnosis(index, selectedDiagnosisOption)
                      }
                      isClearable
                      isSearchable
                      isCreatable
                      createOptionPosition="first"
                      onCreateOption={handleCreateDiagnose}
                      width="300px"
                      placeholder={t("web_client.select_diagnosis")}
                      formatCreateLabel={(inputValue) => (
                        <b>{`${t(
                          "web_client.create_diagnose"
                        )}: ${inputValue}`}</b>
                      )}
                    />
                  </div>
                ))}
              </section>

              <section style={{ marginTop: 30, marginLeft: 15 }}>
                <RegularFontBlack color="black">
                  {t("web_client.select_cause")}
                </RegularFontBlack>
                {diagnosisCausePairs.map((pair, index) => (
                  <div key={index} style={{ marginBottom: 20 }}>
                    <Creatable
                      styles={customStyles}
                      options={causes}
                      value={pair.cause}
                      onChange={(selectedCauseOption) =>
                        updateCause(index, selectedCauseOption)
                      }
                      isClearable
                      isSearchable
                      isCreatable
                      createOptionPosition="first"
                      onCreateOption={handleCreateCause}
                      width="300px"
                      placeholder={t("web_client.select_cause")}
                      formatCreateLabel={(inputValue) => (
                        <b>{`${t(
                          "web_client.create_cause"
                        )}: ${inputValue}`}</b>
                      )}
                    />
                  </div>
                ))}
              </section>

              <div style={{ marginTop: 30 }}>
                <button type="button" onClick={addNewPair}>
                  {t("add_another_pair")}
                </button>
              </div>

              <section style={{ marginTop: 30, marginLeft: 15 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <RegularFont>
                    {t("web_client.patient_needs_disability")}
                  </RegularFont>

                  <input
                    type="checkbox"
                    name="NeedsDisability"
                    checked={patientNeedsDisability}
                    onChange={handlePatientNeedsDisability}
                    style={{ marginLeft: "10px" }}
                  />
                </div>

                {patientNeedsDisability && (
                  <div style={{ marginTop: 30 }}>
                    <RegularFont>
                      {t("web_client.patient_disability_time")}
                    </RegularFont>

                    <input
                      name="patientDisabilityDays"
                      id="patientDisabilityDays"
                      type="number"
                      onChange={(e) =>
                        setPatientDisabilityDays(Number(e.target.value))
                      }
                      props={{
                        value: patientDisabilityDays,
                      }}
                      style={{
                        width: "80px",
                        height: "32px",
                        marginTop: 10,
                        border: "1px solid #E2E8F0",
                        borderRadius: "5px",
                        paddingLeft: 5,
                      }}
                    />
                  </div>
                )}
              </section>

              <div style={{ marginTop: 50, paddingBottom: 50 }}>
                <button
                  disabled={
                    !diagnosisCausePairs.every(
                      (pair) => pair.diagnosis && pair.cause
                    )
                  }
                  type="button"
                  onClick={handleCreateNewDiagnose}
                >
                  {t("create_register")}
                </button>
              </div>
            </section>
          </DiagnosisModal>
        </ModalWrapper>
      </CustomModal>
    </>
  )
}

ChatHeader.defaultProps = {
  name: "",
  showButton: true,
}

ChatHeader.propTypes = {
  name: PropTypes.string,
  showButton: PropTypes.bool,
  firebase: PropTypes.shape().isRequired,
}

export default withFirebase(ChatHeader)
